<template>
    <div>
        <div class='flex items-end mt-6 mb-6'>
            <div>
                <CustomTitle class='m-0'>
                    {{ $t('ttl-organization-users') }}
                </CustomTitle>
            </div>
            <div class='ml-auto flex'>
                <FriendlyButton
                    class='ml-2'
                    label='lbl-invite-organization-user'
                    extra-small
                    no-margin
                    square
                    id='invite-organization-user-button'
                    :action='openInviteUserPopup'
                    symbol='user-plus'
                />
            </div>
        </div>
        <div class='flex mt-6 mb-6'>
            <p class='text-gray-500'>
                <Translated>
                    <template #en>
                        These are all the MyCodaBox users of <b>{{ organization.enterpriseName }}</b>.
                        <br>
                        Active users have access to the underlying environment(s) and are able to manage all clients.
                    </template>
                    <template #nl>
                        Dit zijn alle MyCodaBox gebruikers van <b>{{ organization.enterpriseName }}</b>.
                        <br>
                        Actieve gebruikers hebben toegang tot de onderliggende omgeving(en) en kunnen alle klanten beheren.
                    </template>
                    <template #fr>
                        Il s'agit de tous les utilisateurs MyCodaBox de <b>{{ organization.enterpriseName }}</b>.
                        <br>
                        Les utilisateurs actifs ont accès aux environnements de cette organisation et peuvent gérer tous les clients.
                    </template>
                </Translated>
            </p>
        </div>
        <ContentBox>
            <SearchInput label='placeholder-org-users-search' v-model='userListSearchQuery' class='mb-3' />

            <List :loading='loading'>
                <template #columns>
                    <ListColumn sortable property='email' :sorted-by-property='userListSortedByProperty' @sort='sortUserList'>
                        {{ $t('col-organization-users-email') }}
                    </ListColumn>
                    <ListColumn sortable property='firstName' :sorted-by-property='userListSortedByProperty' @sort='sortUserList'>
                        {{ $t('col-organization-users-name') }}
                    </ListColumn>
                    <ListColumn sortable property='status' :sorted-by-property='userListSortedByProperty' @sort='sortUserList'>
                        {{ $t('col-organization-users-status') }}
                    </ListColumn>
                    <ListColumn>
                        {{ $t('col-organization-users-2fa-status') }}
                        <tooltip faded class='ml-1 font-normal'>
                            <template #trigger>
                                <i class='fa fa-info-circle'></i>
                            </template>
                            <template #content>
                                {{ $t('col-organization-users-2fa-status-info') }}
                            </template>
                        </tooltip>
                    </ListColumn>
                    <ListColumn>
                        {{ $t('col-organization-users-admin') }}
                        <tooltip faded class='ml-1 font-normal'>
                            <template #trigger>
                                <i class='fa fa-info-circle'></i>
                            </template>
                            <template #content>
                                {{ $t('col-organization-users-admin-info') }}
                            </template>
                        </tooltip>
                    </ListColumn>
                    <ListColumn class='text-right'>
                        {{ $t('col-organization-users-actions') }}
                    </ListColumn>
                </template>
                <template #rows>
                    <ListRow v-for='(record, i) in filteredUserList' :key='i'>
                        <ListItem>
                            <strong>{{ record.email }}</strong>
                        </ListItem>
                        <ListItem v-if='record.firstName && record.lastName'>
                            {{ record.firstName }} {{ record.lastName }}
                        </ListItem>
                        <ListItem v-else>
                            -
                        </ListItem>
                        <ListItem>
                            <StatePill
                                :label='$t(`lbl-organization-users-status-${record.status}`)'
                                :background-class='userStatusBackgroundClass(record.status)'
                                :legend='$t(`lgnd-organization-users-status-${record.status}`)'
                            />
                        </ListItem>
                        <ListItem v-if='record.status === "active"'>
                            <StatePill
                                :label='$t(record.has2faEnabled ? "lbl-organization-users-2fa-status-enabled" : "lbl-organization-users-2fa-status-disabled")'
                                :background-class='record.has2faEnabled ? "bg-green-300" : "bg-grey-300"'
                                inline
                            />
                        </ListItem>
                        <ListItem v-else>
                            -
                        </ListItem>
                        <ListItem>
                            <FormToggle
                                name='isAdmin'
                                edit
                                :value='record.role === "admin"'
                                :disabled='record.userId === $store.state.user.userId || record.status === "invitation-expired"'
                                :disabled-info='(() => {
                                    if (record.userId === $store.state.user.userId) {
                                        return $t("lbl-admin-edit-self-forbidden");
                                    } else if (record.status === "invitation-expired") {
                                        return $t("lbl-admin-edit-invitation-expired-forbidden")
                                    }
                                    return "";
                                })()'
                                @change='() => { switchAdminRole(record) }'
                            />
                        </ListItem>
                        <ListItem>
                            <div class='flex justify-end'>
                                <FriendlyButton
                                    v-if='record.userId !== $store.state.user.userId && ["invitation-sent","invitation-expired"].includes(record.status)'
                                    symbol='repeat'
                                    extra-small
                                    no-margin
                                    square
                                    no-text
                                    secondary
                                    no-background
                                    fat-icon
                                    :action='() => openUserResendInvitationPopup(record)'
                                />
                                <FriendlyButton
                                    v-if='record.userId !== $store.state.user.userId'
                                    symbol='trash'
                                    extra-small
                                    no-margin
                                    square
                                    no-text
                                    danger
                                    no-background
                                    fat-icon
                                    :action='() => record.organizationUserId ? openUserRemovalPopup(record) : openDeleteUserInvitationPopup(record)'
                                />
                            </div>
                        </ListItem>
                    </ListRow>
                </template>
            </List>
        </ContentBox>

        <Form ref='userInvitationForm' @submit='submitUserInvitation'>
            <Popup :show='userInvitationPopup' :close='closeInviteUserPopup' small>
                <template #header>
                    {{ $t('ttl-popup-invite-organization-user') }} {{ organization.enterpriseName }}
                </template>

                <p class='text-grey-500 mb-2'>
                    {{ $t('invite-user-text') }}
                </p>
                <p class='text-grey-500 mb-6'>
                    <span class='font-bold text-orange-300'>{{ $t('invite-user-text-note-p1') }}</span> {{ $t('invite-user-text-note-p2') }}
                </p>

                <div>
                    <FormInput
                        name='userInvitationEmail'
                        vid='email'
                        type='email'
                        :label='$t("lbl-popup-invite-organization-user-email")'
                        :placeholder='$t("lbl-email")'
                        edit
                        rules='required|email'
                    />
                    <div class='flex align-middle items-start'>
                        <FormToggle
                            name='inviteAsAdmin'
                            :label='$t("lbl-invite-as-admin")'
                            edit
                            id='inviteAsAdmin'
                            class='my-2'
                        />
                    </div>
                    <div>
                        <!-- TODO: integrate this text in the FormToggle component? -->
                        {{ $t('p-invite-as-admin') }}
                    </div>
                </div>

                <template #buttons>
                    <FriendlyButton
                        label='lbl-cancel'
                        :action='closeInviteUserPopup'
                        symbol='times'
                        small
                        square
                        secondary
                    />
                    <FriendlyButton
                        label='lbl-popup-invite-organization-user'
                        type='submit'
                        small
                        symbol='check'
                        square
                    />
                </template>
            </Popup>
        </Form>

        <Popup :show='userRemovalPopup' :close='closeUserRemovalPopup'>
            <template #header>
                {{ $t('ttl-popup-remove-organization-user') }}
            </template>

            <div>
                <p>
                    <Translated>
                        <template #en>
                            <b>{{ userRemovedDisplayName }}</b> will not be able to access <b>{{ organization.enterpriseName }}</b> via MyCodaBox.
                        </template>
                        <template #fr>
                            <b>{{ userRemovedDisplayName }}</b> ne pourra pas accéder à <b>{{ organization.enterpriseName }}</b> via MyCodaBox.
                        </template>
                        <template #nl>
                            <b>{{ userRemovedDisplayName }}</b> zal geen toegang hebben tot <b>{{ organization.enterpriseName }}</b> via MyCodaBox.
                        </template>
                    </Translated>
                </p>
            </div>
            <template #buttons>
                <FriendlyButton
                    label='lbl-popup-cancel-remove-organization-user'
                    :action='closeUserRemovalPopup'
                    symbol='times'
                    small
                    square
                    secondary
                />
                <FriendlyButton
                    label='lbl-popup-confirm-remove-organization-user'
                    :action='submitUserRemovalPopup'
                    small
                    symbol='check'
                    square
                />
            </template>
        </Popup>

        <Popup :show='deleteUserInvitationPopup' :close='closeDeleteUserInvitationPopup'>
            <template #header>
                {{ $t('ttl-popup-delete-user-invitation') }}
            </template>

            <div>
                <p>
                    <Translated>
                        <template #en>
                            The invitation for <b>{{ userInvitationDeleted.email }}</b> to join <b>{{ organization.enterpriseName }}</b> will no longer be valid.
                        </template>
                        <template #fr>
                            L'invitation pour <b>{{ userInvitationDeleted.email }}</b> à rejoindre <b>{{ organization.enterpriseName }}</b> ne sera plus valide.
                        </template>
                        <template #nl>
                            De uitnodiging voor <b>{{ userInvitationDeleted.email }}</b> om deel uit te maken van <b>{{ organization.enterpriseName }}</b> zal niet langer geldig zijn.
                        </template>
                    </Translated>
                </p>
            </div>
            <template #buttons>
                <FriendlyButton
                    label='lbl-popup-cancel-delete-user-invitation'
                    :action='closeDeleteUserInvitationPopup'
                    symbol='times'
                    small
                    square
                    secondary
                />
                <FriendlyButton
                    label='lbl-popup-confirm-delete-user-invitation'
                    :action='submitDeleteUserInvitationPopup'
                    small
                    symbol='check'
                    square
                />
            </template>
        </Popup>

        <Popup :show='userResendInvitationPopup' :close='closeUserResendInvitationPopup' small>
            <template #header>
                {{ $t('ttl-popup-remove-organization-user') }}
            </template>

            <div>
                <p>
                    <Translated>
                        <template #en>
                            An email will be sent again to <b>{{ userResend.email }}</b> to sign up for a MyCodaBox account or to accept the invitation.
                        </template>
                        <template #fr>
                            Un nouvel e-mail sera envoyé à l'adresse e-mail <b>{{ userResend.email }}</b> pour la création d'un compte MyCodaBox ou pour accepter l'invitation.
                        </template>
                        <template #nl>
                            Er zal opnieuw een e-mail verstuurd worden naar <b>{{ userResend.email }}</b> om zich te registreren voor een MyCodaBox account of om de uitnodiging te accepteren.
                        </template>
                    </Translated>
                </p>
            </div>
            <template #buttons>
                <FriendlyButton
                    label='lbl-popup-cancel-remove-organization-user'
                    :action='closeUserResendInvitationPopup'
                    symbol='times'
                    small
                    square
                    secondary
                />
                <FriendlyButton
                    label='lbl-popup-resend-invitation'
                    :action='submitUserResendInvitationPopup'
                    small
                    symbol='check'
                    square
                />
            </template>
        </Popup>
    </div>
</template>

<script>
    import _ from 'lodash';
    import List from '@/components/List';
    import ListColumn from '@/components/ListColumn';
    import ListRow from '@/components/ListRow';
    import ListItem from '@/components/ListItem';
    import SearchInput from '@/components/SearchInput';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import ContentBox from '@/components/ContentBox';
    import Popup from '@/clientcomponents/Popup.vue';
    import notify from '@/notify.js';
    import CustomTitle from '@/components/Title';
    import { gql } from '@apollo/client/core';
    import StatePill from '@/components/StatePill.vue';
    import Translated from '@/components/Translated';
    import utils from '@/utils';
    import Loader from '@/loader.js';
    import Tooltip from '@/components/Tooltip.vue';
    import { Form } from 'vee-validate';
    import FormInput from '@/components/FormInput';
    import FormToggle from '@/components/FormToggle';

    export default {
        name: 'OrganizationUsersView',
        props: {
            organization: {
                type: Object,
                required: true,
            },
        },
        components: {
            Translated,
            List,
            ListColumn,
            ListRow,
            ListItem,
            SearchInput,
            FriendlyButton,
            ContentBox,
            Popup,
            CustomTitle,
            StatePill,
            Tooltip,
            // eslint-disable-next-line vue/no-reserved-component-names
            Form,
            FormInput,
            FormToggle,
        },
        data () {
            return {
                loading: false,
                records: [],
                userInvitationPopup: false,
                userRemovalPopup: false,
                userRemoved: null,
                deleteUserInvitationPopup: false,
                userInvitationDeleted: false,
                userResendInvitationPopup: false,
                userResend: null,
                switchAdminRoleOngoing: false,
                organizationUserQuery: null,
                userListSearchQuery: '',
                userListSortedByProperty: 'email',
                userListSortedByOrder: 'asc',
            };
        },
        computed: {
            userRemovedDisplayName () {
                if (this.userRemoved) {
                    return this.userRemoved.firstName && this.userRemoved.lastName ? `${this.userRemoved.firstName} ${this.userRemoved.lastName}` : this.userRemoved.email;
                } else {
                    return '';
                }
            },
            userList () {
                if (this.records) {
                    const today = new Date();
                    return this.records.map(record => {
                        let status;
                        if (record.invitationExpiresAt) {
                            status = new Date(record.invitationExpiresAt) > today ? 'invitation-sent' : 'invitation-expired';
                        } else {
                            status = 'active';
                        }
                        return { ...record, status };
                    }).sort((a, b) => { return a.email.localeCompare(b.email); });
                }
                return [];
            },
            sortedUserList () {
                return _.orderBy(this.userList, [item => item[this.userListSortedByProperty]?.toLowerCase()], [this.userListSortedByOrder]);
            },
            filteredUserList () {
                const query = this.userListSearchQuery.trim().toLowerCase();

                if (!query) {
                    return this.sortedUserList;
                }

                return this.sortedUserList.filter((user) => {
                    return (
                        user.email.toLowerCase().includes(query) ||
                        user.firstName?.toLowerCase().includes(query) ||
                        user.lastName?.toLowerCase().includes(query)
                    );
                });
            },
        },
        mounted () {
            this.fetchList();
        },
        methods: {
            async fetchList () {
                this.loading = true;
                this.records = await this.getRecords();
                this.loading = false;
            },
            async fetchListNoSpinner () {
                this.records = await this.getRecords();
            },
            async getRecords () {
                // Get records for the list: combination of user records and invitation records
                try {
                    // Stop a previous query if still in flight
                    await this.stopUserQuery();
                    const results = await new Promise((resolve, reject) => {
                        this.organizationUserQuery = this.$apollo.subscribe({
                            query: gql`query OrganizationUsers($organizationId: String) {
                                organizationUsers(organizationId: $organizationId) {
                                    userId
                                    organizationUserId
                                    role
                                    email
                                    firstName
                                    lastName
                                    has2faEnabled
                                },
                                invitedUsers(organizationId: $organizationId) {
                                    id,
                                    email,
                                    expiresAt,
                                    role
                                }
                            }`,
                            variables: {
                                organizationId: this.$route.params.organizationId,
                            },
                        }).subscribe((results) => {
                            resolve(results);
                        }, (error) => {
                            reject(error);
                        });
                    });
                    // merge the arrays, add state and sort A-Z based on email
                    return [
                        ...results.data.organizationUsers,
                        // rename some attributes for invitations records to avoid confusion:
                        ...results.data.invitedUsers.map((invitation) => {
                            return {
                                invitationId: invitation.id,
                                email: invitation.email,
                                invitationExpiresAt: invitation.expiresAt,
                                role: invitation.role,
                            };
                        }),
                    ];
                } catch (e) {
                    notify.error(this.$t('err-unknown'));
                } finally {
                    this.loading = false;
                }
            },
            async stopUserQuery () {
                if (this.organizationUserQuery) {
                    this.organizationUserQuery.unsubscribe();
                }
            },
            async submitUserInvitation (values) {
                try {
                    const invitationResult = await this.$apollo.query({
                        query: gql`mutation InviteOrganizationUser($organizationId: String, $email: String, $role: String) {
                            inviteOrganizationUser(organizationId: $organizationId, email: $email, role: $role) {
                                errors {
                                    code
                                    detail
                                },
                                invitation {
                                    id
                                    expiresAt
                                }
                            }
                        }`,
                        variables: {
                            organizationId: this.organization.id,
                            email: values.userInvitationEmail,
                            role: values.inviteAsAdmin ? 'admin' : 'regular',
                        },
                    });

                    const errors = invitationResult.data.inviteOrganizationUser.errors;
                    if (errors && errors.length > 0) {
                        notify.error(this.$t('err-add-orga-user'));
                    } else {
                        const invitation = invitationResult.data.inviteOrganizationUser.invitation;
                        this.records.push({
                            invitationId: invitation.id,
                            email: values.userInvitationEmail,
                            invitationExpiresAt: invitation.expiresAt,
                            role: values.inviteAsAdmin ? 'admin' : 'regular',
                        });
                        notify.success(this.$t('succ-add-orga-user', { organizationName: this.organization.enterpriseName }));
                    }
                } catch {
                    notify.error(this.$t('err-add-orga-user'));
                }
                this.fetchList();
                this.closeInviteUserPopup();
            },
            openInviteUserPopup () {
                this.userInvitationPopup = true;
            },
            closeInviteUserPopup () {
                this.userInvitationPopup = false;
            },
            async submitUserResendInvitationPopup () {
                const id = Loader.start();
                if (this.userResend && this.userResend.invitationId) {
                    try {
                        // delete existing invitation
                        const deleteResult = await this.$apollo.query({
                            query: gql`mutation DeleteUserInvitation($invitationId: String) {
                                deleteUserInvitation(invitationId: $invitationId) {
                                    errors {
                                        code
                                        detail
                                    }
                                }
                            }`,
                            variables: {
                                invitationId: this.userResend.invitationId,
                            },
                        });
                        const deleteErrors = deleteResult.data.deleteUserInvitation.errors;
                        if (deleteErrors && deleteErrors.length > 0) {
                            notify.error(this.$t('err-resend-invitation'));
                            return;
                        }
                        // recreate invitation
                        const invitationResult = await this.$apollo.query({
                            query: gql`mutation InviteOrganizationUser($organizationId: String, $email: String, $role: String) {
                                inviteOrganizationUser(organizationId: $organizationId, email: $email, role: $role) {
                                    errors {
                                        code
                                        detail
                                    }
                                    invitation {
                                        id
                                        email
                                        expiresAt
                                        role
                                    }
                                }
                            }`,
                            variables: {
                                organizationId: this.organization.id,
                                email: this.userResend.email,
                                role: this.userResend.role,
                            },
                        });
                        const errors = invitationResult.data.inviteOrganizationUser.errors;
                        if (errors && errors.length > 0) {
                            notify.error(this.$t('err-resend-invitation'));
                            return;
                        } else {
                            const invitation = invitationResult.data.inviteOrganizationUser.invitation;
                            let record = this.records.find(record => record.invitationId === this.userResend.invitationId);
                            record.invitationId = invitation.id;
                            record.invitationExpiresAt = invitation.expiresAt;
                            record.role = this.userResend.role;
                            notify.success(this.$t('succ-resend-invitation'));
                        }
                    } catch (e) {
                        notify.error(this.$t('err-resend-invitation'));
                    }
                } else {
                    notify.error(this.$t('err-resend-invitation'));
                }
                this.fetchList();
                this.closeUserResendInvitationPopup();
                Loader.stop(id);
            },
            async submitUserRemovalPopup () {
                if (this.userRemoved && this.userRemoved.organizationUserId) {
                    try {
                        const removeResult = await this.$apollo.query({
                            query: gql`mutation RemoveOrganizationUser($organizationUserId: String) {
                                removeOrganizationUser(organizationUserId: $organizationUserId) {
                                    errors {
                                        code
                                        detail
                                    }
                                }
                            }`,
                            variables: {
                                organizationUserId: this.userRemoved.organizationUserId,
                            },
                        });

                        const errors = removeResult.data.removeOrganizationUser.errors;
                        if (errors && errors.length > 0) {
                            notify.error(this.$t('err-remove-organization-user'));
                        } else {
                            const organizationName = utils.escapeHTML(this.organization.enterpriseName);
                            const userDisplayName = utils.escapeHTML(this.userRemovedDisplayName);
                            this.records = this.records.filter(record => record.organizationUserId !== this.userRemoved.organizationUserId);
                            notify.success(this.$t('suc-remove-organization-user', { organizationName: organizationName, userDisplayName: userDisplayName }));
                        }
                    } catch {
                        notify.error(this.$t('err-remove-organization-user'));
                    }
                } else {
                    notify.error(this.$t('err-unknown'));
                    return;
                }
                this.fetchList();
                this.closeUserRemovalPopup();
            },
            async submitDeleteUserInvitationPopup () {
                if (this.userInvitationDeleted && this.userInvitationDeleted.invitationId) {
                    try {
                        const deleteResult = await this.$apollo.query({
                            query: gql`mutation DeleteUserInvitation($invitationId: String) {
                                deleteUserInvitation(invitationId: $invitationId) {
                                    errors {
                                        code
                                        detail
                                    }
                                }
                            }`,
                            variables: {
                                invitationId: this.userInvitationDeleted.invitationId,
                            },
                        });

                        const errors = deleteResult.data.deleteUserInvitation.errors;
                        if (errors && errors.length > 0) {
                            notify.error(this.$t('err-delete-user-invitation'));
                        } else {
                            const organizationName = utils.escapeHTML(this.organization.enterpriseName);
                            const email = utils.escapeHTML(this.userInvitationDeleted.email);
                            this.records = this.records.filter(record => record.invitationId !== this.userInvitationDeleted.invitationId);
                            notify.success(this.$t('suc-delete-user-invitation', { email: email, organizationName: organizationName }));
                        }
                    } catch {
                        notify.error(this.$t('err-delete-user-invitation'));
                    }
                } else {
                    notify.error(this.$t('err-unknown'));
                    return;
                }
                this.fetchList();
                this.closeDeleteUserInvitationPopup();
            },
            openUserRemovalPopup (user) {
                this.userRemoved = user;
                this.userRemovalPopup = true;
            },
            closeUserRemovalPopup () {
                this.userRemoved = null;
                this.userRemovalPopup = false;
            },
            openDeleteUserInvitationPopup (userInvitation) {
                this.userInvitationDeleted = userInvitation;
                this.deleteUserInvitationPopup = true;
            },
            closeDeleteUserInvitationPopup () {
                this.userInvitationDeleted = null;
                this.deleteUserInvitationPopup = false;
            },
            openUserResendInvitationPopup (user) {
                this.userResend = user;
                this.userResendInvitationPopup = true;
            },
            closeUserResendInvitationPopup () {
                this.userResend = null;
                this.userResendInvitationPopup = false;
            },
            userStatusBackgroundClass (status) {
                switch (status) {
                    case 'active':
                        return 'bg-green-300';
                    case 'invitation-sent':
                        return 'bg-orange-300';
                    case 'invitation-expired':
                        return 'bg-red-300';
                    default:
                        return 'bg-grey-300';
                }
            },
            async switchAdminRole (user) {
                if (!this.switchAdminRoleOngoing) {
                    await this.stopUserQuery();
                    this.switchAdminRoleOngoing = true;

                    const initialRole = user.role;

                    let role;
                    switch (initialRole) {
                        case 'admin':
                            role = 'regular';
                            break;
                        case 'regular':
                            role = 'admin';
                            break;
                        default:
                            break;
                    }

                    user.role = role;

                    try {
                        let errors;
                        // user is in state invitation-sent, we should therefore update the invitation role
                        if (user.status === 'invitation-sent') {
                            const updateResult = await this.$apollo.query({
                                query: gql`mutation UpdateUserInvitationRole($invitationId: String, $role: String) {
                                    updateUserInvitationRole(invitationId: $invitationId, role: $role) {
                                        errors {
                                            code
                                            detail
                                        }
                                    }
                                }`,
                                variables: {
                                    invitationId: user.invitationId,
                                    role,
                                },
                            });

                            errors = updateResult.data.updateUserInvitationRole.errors;
                        } else if (user.status === 'active') {
                            // user is in state active, we should therefore update the user role
                            const updateResult = await this.$apollo.query({
                                query: gql`mutation UpdateOrganizationUserRole($organizationUserId: String, $role: String) {
                                    updateOrganizationUserRole(organizationUserId: $organizationUserId, role: $role) {
                                        errors {
                                            code
                                            detail
                                        }
                                    }
                                }`,
                                variables: {
                                    organizationUserId: user.organizationUserId,
                                    role,
                                },
                            });

                            errors = updateResult.data.updateOrganizationUserRole.errors;
                        } else {
                            throw new Error('wrong user status');
                        }

                        if (errors && errors.length > 0) {
                            throw new Error('an error happened on role update');
                        } else {
                            notify.success(this.$t('suc-update-user-role'));
                        }
                    } catch (e) {
                        console.error(e);
                        notify.error(this.$t('err-update-user-role'));
                        user.role = initialRole;
                    }

                    this.switchAdminRoleOngoing = false;
                    this.fetchList();
                }
            },
            sortUserList (property, order) {
                this.userListSortedByProperty = property;
                this.userListSortedByOrder = order;
            },
        },
    };
</script>
