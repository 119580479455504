<script>
import { cleanEnterpriseNumber } from '@/utils';
import CustomTitle from '@/components/Title';
import ContentBox from '@/components/ContentBox';

export default {
    name: 'FiduciaryInfo',
    props: {
        organization: {
            type: Object,
        },
    },
    components: {
        CustomTitle,
        ContentBox,
    },
    computed: {
        cleanEnterpriseNumber () {
            return this.organization.enterpriseNumber ? cleanEnterpriseNumber(this.organization.enterpriseNumber) : '-';
        },
    },
};
</script>

<template>
    <div>
        <CustomTitle class='mt-6 mb-6'>
            {{ $t('h-organization-information') }}
        </CustomTitle>
        <content-box class='md:flex'>
            <div class='w-full'>
                <form>
                    <div class='form-group'>
                        <label>{{ $t('lbl-enterprise-name') }}</label>
                        <p class='cb-form-value'>
                            {{ organization.enterpriseName }}
                        </p>
                    </div>
                    <div class='form-group'>
                        <label>{{ $t('lbl-enterpriseNumber') }}</label>
                        <p class='cb-form-value'>
                            {{ cleanEnterpriseNumber }}
                        </p>
                    </div>
                    <div class='form-group'>
                        <label>{{ $t('lbl-address') }}</label>
                        <p class='cb-form-value'>
                            {{ organization.address }}
                        </p>
                        <p class='cb-form-value' v-if='organization.address2'>
                            {{ organization.address2 }}
                        </p>
                        <p class='cb-form-value'>
                            {{ organization.zip }}
                        </p>
                        <p class='cb-form-value'>
                            {{ organization.city }}
                        </p>
                    </div>
                </form>
            </div>
            <div class='w-full'>
                <form>
                    <div class='form-group'>
                        <label>{{ $t('lbl-representative-name') }}</label>
                        <p class='cb-form-value'>
                            {{ organization.representativeName }}
                        </p>
                    </div>
                    <div class='form-group'>
                        <label>{{ $t('lbl-representative-function') }}</label>
                        <p class='cb-form-value'>
                            {{ organization.representativeFunction }}
                        </p>
                    </div>
                </form>
            </div>
        </content-box>
    </div>
</template>

<style scoped>
label {
    @apply ml-0;
}
</style>
