import { createRouter, createWebHashHistory } from 'vue-router';
import notify from '@/notify';
import { t } from '@/i18n';
import store from './store';
import utils from './utils.js';
import { authGuard, onlyUnauthenticatedCheck }  from '@/auth/authGuard';

import Signup from './user/SignUp.vue';
import ConfirmInvitation from './user/ConfirmInvitation.vue';
import MaintenancePage from './common/MaintenancePage.vue';

import EmailVerificationWizard from './client/EmailVerificationWizard.vue';
import RestartEmailVerification from './client/RestartEmailVerification.vue';

import Fiduciary from './fiduciary/Fiduciary.vue';
import Dashboard from './fiduciary/Dashboard.vue';
import CodaSearch from './fiduciary/CodaSearch.vue';
import SodaSearch from './fiduciary/SodaSearch.vue';
import ClientSearch from './fiduciary/ClientSearch.vue';
import ClientTransfer from './fiduciary/ClientTransfer.vue';
import ClientRegularTransferList from './fiduciary/ClientRegularTransferList.vue';
import ClientPlatformTransferList from './fiduciary/ClientPlatformTransferList.vue';
import Voila2List from './fiduciary/Voila2List.vue';
import FiduClient from './fiduciary/Client.vue';
import NewClient from './fiduciary/NewClient';
import ClientInfo from './fiduciary/ClientInfo';
import ClientCoda from './fiduciary/ClientCoda';
import ClientSoda from './fiduciary/ClientSoda';
import ClientVoila2 from './fiduciary/ClientVoila2.vue';
import ClientCaro from './fiduciary/ClientCaro';
import Services from './fiduciary/Services.vue';
import FiduciaryInfo from './fiduciary/FiduciaryInfo.vue';
import Archived from './fiduciary/Archived.vue';
import FiduciarySettings from './fiduciary/FiduciarySettings.vue';
import CreditCardStatementClientsList from './fiduciary/CreditCardStatementClientsList.vue';
import ConnectApiConsentPage from './fiduciary/ConnectApiConsentPage.vue';

import Reseller from './reseller/Reseller.vue';
import RslrFiduSearch from './reseller/FiduSearch.vue';
import RslrCodaSearch from './reseller/CodaSearch.vue';
import RslrSodaSearch from './reseller/SodaSearch.vue';

import Unsubscribe from './components/Unsubscribe.vue';

import OrganizationView from './organization/OrganizationView';
import OrganizationSettingsView from './organization/settings/OrganizationSettingsView';
import OrganizationUsersView from './organization/settings/OrganizationUsersView';
import OrganizationDeliveryView from './organization/settings/OrganizationDeliveryView';
import OrganizationInformation from './organization/settings/OrganizationInformation';

import Home from '@/views/Home';

import NotFoundView from '@/views/NotFoundView';

import Login from '@/common/Login.vue';
import ForgotPassword from '@/common/ForgotPassword.vue';
import ResetPassword from '@/common/ResetPassword.vue';

function getUserRedirectUrl (path) {
    let url;
    const organizations = store.state.user.organizationIds;
    const resellers = store.state.user.resellerIds;

    if (organizations.length > 0) {
        const previouslySelectedOrganizationId = localStorage['selectedOrganizationId'];
        // if the user has access that more than an organization
        if (previouslySelectedOrganizationId && organizations.some(orgId => orgId === previouslySelectedOrganizationId)) {
            // use in priority what is saved locally AND if the user has access
            url = { name: 'organization', params: { organizationId: previouslySelectedOrganizationId } };
        } else {
            // otherwise, take the first one
            url = { name: 'organization', params: { organizationId: organizations[0] } };
        }
    } else if (resellers.length > 0) {
        url = { name: 'rslr-fidu-search', params: { resellerId: resellers[0] } };
    } else {
        // if the user has access to no organizations and no resellers, redirect him to the homepage
        url = { name: 'home' };
    }
    return url;
}

async function defaultRootRedirect (to, _, next) {
    // checks if there is a session ongoing
    try {
        await store.dispatch('checkSession');
    } catch {
        // it's most likely a normal flow, so we do not display any error here.
        // if there is any, it would be handled in authGuard
        return next({ name: 'login' });
    }

    // 2. Get or refresh the user
    try {
        await store.dispatch('loadUserCached');
    } catch {
        // Question; I think it's mostly an unwanted case here, as there whould be any session ?
        notify.error(t('err-unknown'));
        return next({ name: 'login' });
    }

    let redirect = localStorage.getItem('redirect') || getUserRedirectUrl(to.path);
    localStorage.removeItem('redirect');

    if (redirect) {
        return next(redirect);
    }

    return next();
}

export const routes = [
    {
        path: '/',
        name: 'index',
        beforeEnter: defaultRootRedirect,
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            authGuard: {
                restricted: true,
            },
        },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: onlyUnauthenticatedCheck,
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        beforeEnter: onlyUnauthenticatedCheck,
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword,
        beforeEnter: onlyUnauthenticatedCheck,
    },
    {
        path: '/verify/:token',
        name: 'email-verification',
        component: EmailVerificationWizard,
        meta: {
            title: 'Email Verification',
            entry: 'accept',
        },
    },
    {
        path: '/refuse-verification/:token',
        name: 'email-verification-refuse',
        component: EmailVerificationWizard,
        meta: {
            title: 'Email Verification',
            entry: 'refuse',
        },
    },
    {
        path: '/verify/restart/:token',
        name: 'restart-email-verification',
        component: RestartEmailVerification,
        meta: {
            title: 'Email Verification refresh',
        },
    },
    {
        path: '/sign-up/:invitationToken',
        name: 'signup',
        component: Signup,
        meta: { title: 'Signup' },
    },
    {
        path: '/confirm-invitation/:token',
        name: 'confirmInvitation',
        component: ConfirmInvitation,
        meta: {
            title: 'Confirm User Invitation',
            authGuard: {
                restricted: true,
            },
        },
    },
    {
        path: '/organization/:organizationId',
        name: 'organization',
        component: OrganizationView,
        meta: {
            authGuard: {
                restricted: true,
                entityType: 'organization',
            },
        },
        children: [
            {
                path: 'administration',
                name: 'org-administration',
                component: OrganizationSettingsView,
                children: [
                    {
                        path: '',
                        name: 'organization-administration-users',
                        component: OrganizationUsersView,
                        meta: {
                            title: 'Users',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                                restrictedToOrgRole: 'admin',
                            },
                        },
                    },
                    {
                        path: 'connect',
                        name: 'organization-administration-connect',
                        component: OrganizationDeliveryView,
                        meta: {
                            title: 'CodaBox Connect',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                                restrictedToOrgRole: 'admin',
                            },
                        },
                    },
                    {
                        path: 'information',
                        name: 'organization-administration-information',
                        component: OrganizationInformation,
                        meta: {
                            title: 'Organization Information',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                                restrictedToOrgRole: 'admin',
                            },
                        },
                    },
                ],
                meta: {
                    title: 'Organization settings',
                    authGuard: {
                        restricted: true,
                        entityType: 'organization',
                        restrictedToOrgRole: 'admin',
                    },
                },
            },
            {
                path: 'environment/:environmentId',
                name: 'environment',
                component: Fiduciary,
                meta: {
                    title: 'Fiduciary',
                    authGuard: {
                        restricted: true,
                        entityType: 'organization',
                    },
                },
                children: [
                    {
                        path: '',
                        name: 'dashboard',
                        component: Dashboard,
                        meta: {
                            title: 'Fiduciary Dashboard',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                            },
                        },
                    },
                    {
                        path: 'coda',
                        name: 'fidu-coda-search',
                        component: CodaSearch,
                        meta: {
                            title: 'Coda Search',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                            },
                        },
                    },
                    {
                        path: 'soda',
                        name: 'fidu-soda-search',
                        component: SodaSearch,
                        meta: {
                            title: 'Soda Search',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                            },
                        },
                    },
                    {
                        path: 'purchases',
                        name: 'fidu-purchase-search',
                        component: Voila2List,
                        meta: {
                            title: 'Purchase Search',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                            },
                        },
                    },
                    {
                        path: 'caro',
                        name: 'fidu-ccs-clients-list',
                        component: CreditCardStatementClientsList,
                        meta: {
                            title: 'Credit card statements',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                            },
                        },
                    },
                    {
                        path: 'contracts',
                        name: 'fidu-services-root',
                        component: Services,
                        meta: {
                            title: 'Contracts & Services',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                            },
                        },
                    },
                    {
                        path: 'archived/:type/:uid/',
                        name: 'archived',
                        component: Archived,
                        meta: {
                            title: 'Contracts & Services',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                            },
                        },
                    },
                    {
                        path: 'clients',
                        name: 'fidu-client-search',
                        component: ClientSearch,
                        meta: {
                            title: 'Client Search',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                            },
                        },
                    },
                    {
                        path: 'clients/nopurchases',
                        name: 'fidu-client-search-no-purchases',
                        component: ClientSearch,
                        meta: {
                            title: 'Client Search',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                            },
                        },
                    },
                    {
                        path: 'clients/transfers',
                        name: 'fidu-client-transfer-lists',
                        component: ClientTransfer,
                        meta: {
                            title: 'Client Transfer',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                            },
                        },
                        children: [
                            {
                                path: '',
                                name: 'fidu-client-regular-transfer-list',
                                component: ClientRegularTransferList,
                                meta: {
                                    title: 'Client Regular Transfer List',
                                    authGuard: {
                                        restricted: true,
                                        entityType: 'organization',
                                    },
                                },
                            },
                            {
                                path: 'platform',
                                name: 'fidu-client-platform-transfer-list',
                                component: ClientPlatformTransferList,
                                meta: {
                                    title: 'Client Platform Transfer List',
                                    authGuard: {
                                        restricted: true,
                                        entityType: 'organization',
                                    },
                                },
                            },
                        ],
                    },
                    {
                        path: 'info',
                        name: 'fidu-info',
                        component: FiduciaryInfo,
                        meta: {
                            title: 'Fiduciary Info',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                            },
                        },
                    },
                    {
                        path: 'client',
                        name: 'fidu-client-root',
                        component: FiduClient,
                        meta: {
                            title: 'Fiduciary Client',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                            },
                        },
                    },
                    {
                        path: 'client/new',
                        name: 'fidu-client-new',
                        component: NewClient,
                        meta: {
                            title: 'New Fiduciary Client',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                            },
                        },
                    },
                    {
                        path: 'client/:uid/',
                        component: FiduClient,
                        name: 'client-detail-page',
                        meta: {
                            title: 'Fiduciary Client',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                            },
                        },
                        props: (route) => ({
                            routeName: route.name,
                            clientId: route.params.uid,
                        }),
                        children: [
                            {
                                path: '',
                                name: 'fidu-client-uid-info',
                                component: ClientInfo,
                                meta: {
                                    title: 'Fiduciary Client Info',
                                    authGuard: {
                                        restricted: true,
                                        entityType: 'organization',
                                    },
                                },
                            },
                            {
                                path: 'coda',
                                name: 'fidu-client-uid-coda',
                                component: ClientCoda,
                                meta: {
                                    title: 'Fiduciary Client Coda',
                                    authGuard: {
                                        restricted: true,
                                        entityType: 'organization',
                                    },
                                },
                            },
                            {
                                path: 'soda',
                                name: 'fidu-client-uid-soda',
                                component: ClientSoda,
                                meta: {
                                    title: 'Fiduciary Client Soda',
                                    authGuard: {
                                        restricted: true,
                                        entityType: 'organization',
                                    },
                                },
                            },
                            {
                                path: 'voila',
                                name: 'fidu-client-uid-voila',
                                component: ClientVoila2,
                                meta: {
                                    title: 'Fiduciary Client VOILA',
                                    authGuard: {
                                        restricted: true,
                                        entityType: 'organization',
                                    },
                                },
                            },
                            {
                                path: 'caro',
                                name: 'fidu-client-uid-credit-card-statement',
                                component: ClientCaro,
                                meta: {
                                    title: 'Fiduciary Client Credit Card Statement',
                                    authGuard: {
                                        restricted: true,
                                        entityType: 'organization',
                                    },
                                },
                            },
                        ],
                    },
                    {
                        path: 'settings',
                        name: 'fidu-settings',
                        component: FiduciarySettings,
                        meta: {
                            title: 'Fiduciary settings',
                            authGuard: {
                                restricted: true,
                                entityType: 'organization',
                            },
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/fiduciary/connect-api-consent',
        name: 'connect-api-consent',
        component: ConnectApiConsentPage,
        meta: {
            title: 'Connect Api consent',
            authGuard: {
                restricted: true,
            },
        },
    },
    {
        path: '/reseller/:resellerId',
        name: 'reseller',
        component: Reseller,
        meta: {
            title: 'Reseller',
            authGuard: {
                restricted: true,
                entityType: 'reseller',
            },
        },
        children: [
            {
                path: 'fiduciaries',
                name: 'rslr-fidu-search',
                component: RslrFiduSearch,
                meta: {
                    title: 'Fiduciaries',
                    authGuard: {
                        restricted: true,
                        entityType: 'reseller',
                    },
                },
            },
            {
                path: 'coda',
                name: 'rslr-coda-search',
                component: RslrCodaSearch,
                meta: {
                    title: 'Coda Search',
                    authGuard: {
                        restricted: true,
                        entityType: 'reseller',
                    },
                },
            },
            {
                path: 'soda',
                name: 'rslr-soda-search',
                component: RslrSodaSearch,
                meta: {
                    title: 'Soda Search',
                    authGuard: {
                        restricted: true,
                        entityType: 'reseller',
                    },
                },
            },
        ],
    },
    {
        path: '/unsubscribe',
        name: 'unsubscribe',
        component: Unsubscribe,
    },
    // todo: create 404 page
    { path: '/:pathMatch(.*)*', name: '404', component: NotFoundView },
    { path: '/maintenance', component: MaintenancePage },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    /* detect query params to enable flags */
    if (to.query.flag) {
        for (let flag of to.query.flag.split(',')) {
            store.commit('setFlag', flag);
        }
    }
    next();
});

router.beforeEach((to, from, next) => {
    /* detect maintenance mode */
    if (store.state.maintenance && to.fullPath !== '/maintenance') {
        next('/maintenance');
    } else {
        next();
    }
});

router.beforeEach(async (to, from, next) => {
    /* handle user load, check roles and access
    *
    * "restricted" in authGuard is mostly there to be explicit and avoid empty dict hen there no other restrictions
    *  */
    if (to.meta.authGuard?.restricted) {
        await authGuard(to, from, next);
    } else {
        next();
    }
});

/* -- Scrolling -- */

router.afterEach((to, from) => {
    if (!(to.name === 'fidu-client-uid-info')) {
        utils.scrollTop();
    }
});

export default router;
